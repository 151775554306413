import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store'
import axios from 'axios'
import "@/assets/sass/login.sass";

//import './theme.scss'
import {TokenService} from '@/services/storage_service'
import ApiService from "@/services/api_service"
//import {createI18n} from "vue-i18n"
import {useLocale} from "@/composables/useLocale"
import mitt from "mitt"
import {IData} from "@/types"

const emitter = mitt();

const app = createApp(App);

if (!window['disarea']) {
  window['disarea'] = {}
}
window['disarea']['isDebugMode'] = false

//axios.defaults.withCredentials = true;
axios.defaults.headers.common['Content-Type'] = "application/x-www-form-urlencoded"
app.config.globalProperties.$http = axios;

if (TokenService.getToken()) {
   ApiService.setHeader()
}
ApiService.mount401Interceptor();

/*
const i18options = {
  legacy: false,
  locale: 'en_EN', // set locale
  messages: {}, // set locale messages
  warnHtmlMessage: false, // https://vue-i18n.intlify.dev/api/composition.html#warnhtmlmessage
  missingWarn: false, // https://vue-i18n.intlify.dev/api/composition.html#missingwarn
}
const i18n = createI18n(i18options)

app.config.globalProperties.$i18n = i18n.global
*/
// if (i18options.messages && i18options.locale && i18options.messages[i18options.locale] && i18options.messages[i18options.locale]['An error ocurred, please try again']) {
//   ApiService.setDefaultErrorMessage(i18options.messages[i18options.locale]['An error ocurred, please try again'])
// }

const { createLocale } = useLocale()
const i18n = createLocale('en_EN', {})
window['disarea']['i18n'] = i18n

app.provide('emitter', emitter);

app.config.errorHandler = (err, vm, info) => {
  const errObj = err as IData

  try {
    const isChunkLoadError = window['disarea'].isChunkLoadError(errObj)
    //alert(`index main.ts app.config.errorHandler isChunkLoadError: ${isChunkLoadError} | ${errObj.message}`)
    if (isChunkLoadError) {
      const dataForLog = {
        source: 'login main app.config.errorHandler',
        error: {
          name: errObj.name,
          message: errObj.message
        }
      }
      window['disarea'].reloadOnError('', dataForLog)
    } else {
      window['disarea']['errorLogger']['logData']({
        source: 'login main app.config.errorHandler',
        message: errObj?.message,
        info,
        stack: errObj?.stack,
      })
    }
  } catch (e) {
    console.log('login main app.config.errorHandler exception', e)
    if (window['disarea'] && window['disarea']['errorLogger'] && typeof window['disarea']['errorLogger']['logData'] === 'function') {
      window['disarea']['errorLogger']['logData']({'index main app.config.errorHandler exception': e.message})
    }
  }
}



app.use(store).use(router).use(i18n).mount('#app');

console.log('main login', process.env, process, i18n)
