import AuthService from "@/services/auth_service";
import {onBeforeMount, ref} from "vue";
import {useRoute} from "vue-router"
import { useI18n } from 'vue-i18n'
import ApiService from "@/services/api_service"
import {useStore} from "vuex"
import store from "@/store"

// import {createI18n} from "vue-i18n"
// import ApiService from "@/services/api_service"

const isPreloaded = ref(false)
const login = ref('')
const login_top = ref('')
const login_top2x = ref('')
const loginMessage = ref('')
const loginTitle = ref('')
const msDomain = ref('')
const gapsDomain = ref('')
const demoDomain = ref(false)
const lastUpdated = ref('')
const companyURL = ref('')
const remember = ref(false)
const warning = ref('')
const alerts = ref({})
const customLogo = ref(false)
const providerRedirectUrl = ref('')
//const subdomain = ref('')

/*
 login,
 msDomain,
 gapsDomain,
 demoDomain,
 rm,
 lastUpdate,
 company_url,
 login_top,
 login_top2x,
 login_message,
 warning,
 redirect_delay,
 redirect
*/
export const useLogin = ():any => {
  const store = useStore()

  onBeforeMount(() => {
    const isDebugMode = window['disarea']['isDebugMode']
    if (isDebugMode) {
      const params = new URLSearchParams(location.search)
      const serverParam = store.getters['dev/serverParam']
      if (serverParam) {
        params.delete("serverParam")
        params.append("serverParam", serverParam)
        const newParams = {}
        for (const [key, value] of params.entries()) {
          newParams[key] = value
        }
        console.log('onBeforeMount', params.toString())
        history.replaceState(newParams, params.toString(), "?"+params.toString())
      }
    }
  })

  const preload = function () {
    console.log('preload test', 3, store.getters['dev/serverParam'])
    const route = useRoute()

    const {setLocaleMessage, locale} = useI18n()
    console.log('start preload', route);
    //let redirectDelay = 3000;
    isPreloaded.value = false;
    return AuthService.preload_login().then(response => {
      console.log('preload response', response)

      const responseData = response.data
      const xdata = responseData.xdata
      const redirectURL = xdata.redirect || responseData.redirect
      const redirectDelay = xdata.redirect_delay || responseData.redirect_delay || 0

      if (redirectURL) {
        setTimeout(() => { window.location.href = redirectURL }, redirectDelay)
        return
      }

      window['disarea']['isDebugMode'] = !!xdata?.config?.debug && process.env.VUE_APP_DOMAIN_TYPE !== 'release'
      if (xdata.serverParam) {
        store.commit('dev/setServerParam', xdata.serverParam)
      }

      isPreloaded.value = true;
      if (xdata.login) {
        login.value = xdata.login;
      }
      if (xdata.PROVIDER_REDIRECT_URL) {
        providerRedirectUrl.value = xdata.PROVIDER_REDIRECT_URL
      }
      // if (xdata.subdomain) {
      //   subdomain.value = xdata.subdomain
      // }
      msDomain.value = xdata.msDomain;
      gapsDomain.value = xdata.gapsDomain;
      demoDomain.value = xdata.demoDomain;
      remember.value = xdata.rm;
      if (xdata.lastUpdated) {
        lastUpdated.value = xdata.lastUpdated;
      }
      if (xdata.company_url) {
        companyURL.value = xdata.company_url;
      }
      if (xdata.customLogo) {
        customLogo.value = true
      }
      login_top.value = xdata.login_top
      login_top2x.value = xdata.login_top2x
      loginMessage.value = xdata.login_msg
      loginTitle.value = xdata.login_title
      warning.value = xdata.messages || ''
      //alerts.value = xdata.alerts || {};

      const alertType = responseData.isError ? 'error' : 'success'
      const alertMessage = responseData.message

      if (alertMessage) {
        alerts.value = [{type: alertType, text: alertMessage}]
      }

      // if (xdata.redirect && xdata.redirect != '') {
      //   if (xdata.redirectDelay) {
      //     redirectDelay = xdata.redirect_delay;
      //   }
      //   setTimeout(()=>{
      //     window.location.href = xdata.redirect;
      //   }, redirectDelay);
      // }


      if (xdata.localeParams?.locale && xdata.localeParams?.messages && xdata.localeParams.messages[xdata.localeParams.locale]) {
        setLocaleMessage(xdata.localeParams.locale, xdata.localeParams.messages[xdata.localeParams.locale])
        locale.value = xdata.localeParams.locale
        ApiService.setDefaultErrorMessage(xdata.localeParams.messages[xdata.localeParams.locale]['An error ocurred, please try again'])

        const response = xdata
        const i18n = window['disarea']['i18n']
        if (window['disarea']['isDebugMode'] && response.localeParams.locale === 'ru_RU') {
          const messagesCopy = {...response.localeParams.messages[response.localeParams.locale]}
          const initial_i18n_t = i18n.global.t
          i18n.global.t = (stringKey) => {
            const valueInTranslationFile = messagesCopy[stringKey]
            let translateResult = initial_i18n_t(stringKey)
            if (!valueInTranslationFile) {
              translateResult = '%' + translateResult + '%'
            }
            console.log('localeParams', valueInTranslationFile, translateResult)
            return translateResult
          }
        }

      }

      store.commit('setConfig', xdata.config)

      // const i18options = {
      //   legacy: false,
      //   locale: 'login', // set locale
      //   messages: xdata.localeParams.messages, // set locale messages
      //   warnHtmlMessage: false, // https://vue-i18n.intlify.dev/api/composition.html#warnhtmlmessage
      //   missingWarn: false, // https://vue-i18n.intlify.dev/api/composition.html#missingwarn
      // }
      // const i18n = createI18n(i18options)
      //
      // app.config.globalProperties.$i18n = i18n.global
      //
      // if (i18options.messages && i18options.locale && i18options.messages[i18options.locale] && i18options.messages[i18options.locale]['An error ocurred, please try again']) {
      //   ApiService.setDefaultErrorMessage(i18options.messages[i18options.locale]['An error ocurred, please try again'])
      // }


    }).catch((error) => {
      // if (error.redirect && error.redirect != '') {
      //   let redirectDelay = 0
      //   if (error.redirectDelay) {
      //     redirectDelay = error.redirect_delay;
      //   }
      //   setTimeout(()=>{
      //     window.location.href = error.redirect;
      //   }, redirectDelay);
      //   return
      // }
      console.log('preload error', error)
      isPreloaded.value = true;
    })
  }

  const clearAlerts = () => {
    alerts.value = []
  }

  return {
    login,
    login_top,
    login_top2x,
    loginMessage,
    loginTitle,
    msDomain,
    gapsDomain,
    demoDomain,
    lastUpdated,
    companyURL,
    customLogo,
    isPreloaded,
    remember,
    warning,
    alerts,
    preload,
    clearAlerts,
    providerRedirectUrl,
    //subdomain,
  }
}
