import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";

const getGlobalProps = (route) => {
  const globalProps = {}
  const isDebugMode = window['disarea']['isDebugMode']
  console.log('Login router isDebugMode', isDebugMode)

  if (isDebugMode) {
    Object.assign(globalProps, {
      serverParam: route.query.serverParam || '',
    })
  }
  return globalProps
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    props: (route)=>{
      return Object.assign({
      }, getGlobalProps(route))
    },
    component: () => import('@/views/TheLogin'),
    meta: {
      layout: 'login'
    }
  },
  {
    path: '/forgot_pass',
    name: 'ForgotPass',
    props: (route)=>{
      return Object.assign({
        preFillLogin: route.query.email
      }, getGlobalProps(route))
    },
    component: () => import('@/views/ForgotPass/ForgotPass.vue'),
    meta: {
      layout: 'login'
    }
  },
  {
    path: '/reset_pass',
    redirect: '/forgot_pass'
  },
  {
    path: '/reset_pass/:login/:hasCode?/:newUser?',
    name: 'ResetPass',
    props: (route)=>{
      console.log('ResetPass route', route.params)
      return Object.assign({
        ...route.params
      }, getGlobalProps(route))
    },
    component: () => import('@/views/ResetPass/ResetPass.vue'),
    meta: {
      layout: 'login'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
    // name: 'rout404',
    // component: NotFound,
    // meta: {
    //   layout: 'main'
    // }
  }

]

const router = createRouter({
  history: createWebHistory( '/' + 'login/'), // process.env.BASE_URL
  routes
})

// router.beforeEach((to, from, next) => {
//   window.location.replace('/')
// })

// Handle missing chunks
router.onError((error, to, from) => {
  /*
    const pattern = /Loading chunk (.*) failed/g;
    const isChunkLoadFailedMessage = typeof error.message === 'string' && error.message.match(pattern)
    //const targetPath = router.history.pending.fullPath

    const isChunkLoadError = error.name === 'ChunkLoadError' || isChunkLoadFailedMessage
  */

  try {
    const isChunkLoadError = window['disarea'].isChunkLoadError(error)
    console.log('router login onError', isChunkLoadError, error, to, from)
    //alert(`router onError isChunkLoadError: ${isChunkLoadError} | ${error.message}`)

    if (isChunkLoadError) {
      const dataForLog = {
        from: from.fullPath,
        to: to.fullPath,
        error: {
          name: error.name,
          message: error.message
        }
      }
      window['disarea'].reloadOnError(to.fullPath || '', dataForLog)
    }
  } catch (e) {
    console.log('router login onError exception', e)
    if (window['disarea'] && window['disarea']['errorLogger'] && typeof window['disarea']['errorLogger']['logData'] === 'function') {
      window['disarea']['errorLogger']['logData']({'router login onError exception': e.message})
    }
  }
})


export default router
